import React, { useState } from "react";
import "./Welcome.css";

import { ScaleLoader } from "react-spinners";

const Welcome = () => {
  const [add, SetAdd] = useState(false);
  useState(() => {
    setTimeout(() => {
      SetAdd(true);
    }, 1500);
  }, []);

  return (
    <div className={`welcome ${add && "welcome-anim"}`}>
      <div className="welcome-wrapper">
        <div style={{ display: "flex", alignItems: "center" }}>
          <p className={add && "logo"}>Welcome</p>
        </div>

        {!add && <ScaleLoader color="#fff" />}
      </div>

      {/* <div className="loader">
        <div className="inner-loader"></div>
      </div> */}
    </div>
  );
};

export default Welcome;
