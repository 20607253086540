import React, { useEffect } from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/krishthree-removebg.png";
import HeaderSocials from "./HeaderSocials";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Header = ({ setActive }) => {
  useEffect(() => {
    let sections = document.querySelectorAll("section");
    // let navLinks = document.querySelectorAll("nav a");
    console.log(sections);
    window.onscroll = () => {
      sections.forEach((sec) => {
        let top = window.scrollY;
        let offset = sec.offsetTop - 180;
        let height = sec.offsetHeight;
        let id = sec.getAttribute("id");

        if (top <= 100) {
          setActive("#");
        } else if (top >= offset && top < offset + height) {
          // navLinks.forEach((links) => {
          //   links.classList.remove("active");
          //   document
          //     .querySelector("nav a[href*=" + id + "]")
          //     .classList.add("active");
          // });

          setActive(`#${id}`);
        }
      });
    };
  }, []);
  return (
    <header>
      <div
        className="container header__container"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h5>Hello I'm</h5>
        <h1>S Krishna Reddy</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CTA />
        <HeaderSocials />
        <div className="me" data-aos="fade-up" data-aos-duration="1500">
          <LazyLoadImage
            alt="me"
            effect="blur"
            src={ME}
            style={{ transform: "rotate(-2deg" }}
          />
          {/* <img src={ME} alt="me" style={{ transform: "rotate(-2deg" }} /> */}
        </div>

        <a href="#contact" className="scrool__down">
          Scrool Down
        </a>
      </div>
    </header>
  );
};

export default Header;
