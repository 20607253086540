import React from "react";
import "./testimonials.css";
import AVT1 from "../../assets/avatar1.jpg";
import AVT2 from "../../assets/avatar2.jpg";
import AVT3 from "../../assets/avatar3.jpg";
import AVT4 from "../../assets/avatar4.jpg";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: AVT1,
    name: "Tina Sofi",
    review:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit Deserunt at nesciunt perspiciatis laboriosam hic sequi aliquid minima dignissimos, ullam eveniet iste consectetur. Ipsam necessitatibus voluptatum, natus excepturi ab praesentium rem.",
  },
  {
    avatar: AVT2,
    name: "Alex",
    review:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit Deserunt at nesciunt perspiciatis laboriosam hic sequi aliquid minima dignissimos, ullam eveniet iste consectetur. Ipsam necessitatibus voluptatum, natus excepturi ab praesentium rem.",
  },
  {
    avatar: AVT3,
    name: "John",
    review:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit Deserunt at nesciunt perspiciatis laboriosam hic sequi aliquid minima dignissimos, ullam eveniet iste consectetur. Ipsam necessitatibus voluptatum, natus excepturi ab praesentium rem.",
  },
  {
    avatar: AVT4,
    name: "Rubina",
    review:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit Deserunt at nesciunt perspiciatis laboriosam hic sequi aliquid minima dignissimos, ullam eveniet iste consectetur. Ipsam necessitatibus voluptatum, natus excepturi ab praesentium rem.",
  },
];
const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        autoplay={true}
        pagination={{ clickable: true }}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide className="testimonial" key={index}>
              <div className="client__avatar">
                <img src={item.avatar} alt="" />
              </div>
              <h5 className="client__name">{item.name}</h5>
              <small className="client__review">{item.review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
