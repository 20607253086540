import React from "react";
import "./about.css";
import ME from "../../assets/me-ab.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container ">
        <div
          className="about__me"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <div className="about__me-image">
            <LazyLoadImage alt="me" effect="blur" src={ME} />
            {/* <img src={ME} alt="me" /> */}
          </div>
        </div>

        <div
          className="about__content"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experinece</h5>
              <small>1+ Years Working</small>
            </article>

            {/* <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>100+ Worldwide</small>
            </article> */}

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </article>
          </div>

          <p>
            A versatile, hardworking individual, driven to meet or exceed a
            company's expectations to deliver high-quality software products.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
